import React, { FC } from 'react'

const RealmLogo: FC<any> = (props) => (
  <svg viewBox="0 0 836 354" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M770.301 103L768.908 104.393L768.88 114.822C768.88 144.085 765.945 147.049 736.697 147.049H726.252L724.859 148.442V158.558L726.252 159.951L736.681 159.979C765.945 159.979 768.908 162.915 768.908 192.162V202.607L770.301 204H780.418L781.811 202.607L781.838 192.178C781.838 162.915 784.774 159.951 814.021 159.951H824.467L825.859 158.558V158.531V148.442L824.467 147.049L814.037 147.021C784.774 147.021 781.811 144.085 781.811 114.838V104.393L780.418 103H770.301Z" />
    <path d="M677.26 170L675.164 172.096L675.122 187.792C675.122 231.831 668.691 238.305 624.675 238.305H608.955L606.859 240.4V251.599L608.955 253.695L624.651 253.737C668.691 253.737 675.164 260.169 675.164 304.184V319.904L677.26 322H688.459L690.555 319.904L690.597 304.208C690.597 260.169 697.028 253.695 741.044 253.695H756.763L758.859 251.6V251.558V240.4L756.763 238.305L741.068 238.263C697.028 238.263 690.555 231.831 690.555 187.816V172.096L688.459 170H677.26Z" />
    <path d="M397.949 1.81991V176.385L399.768 178.205H418.847L420.665 176.385V1.81991L418.847 0H399.768L397.949 1.81991Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M254.169 143.232L254.004 143.718C250.125 155.112 243.602 164.61 234.503 171.257C225.402 177.906 213.782 181.66 199.787 181.66C181.517 181.66 166.621 174.827 156.305 162.994C146.002 151.175 140.337 134.451 140.337 114.768C140.337 95.0859 146.002 78.362 156.305 66.5433C166.621 54.7102 181.517 47.8765 199.787 47.8765C218.044 47.8765 232.338 54.3319 242.048 65.9419C251.738 77.5273 256.77 94.1312 256.77 114.275V115.923L252.485 120.211H169.385L163.847 125.974C165.113 135.41 168.494 144.331 174.273 150.911C180.124 157.573 188.482 161.892 199.787 161.892C215.397 161.892 225.445 153.998 231.462 142.918L231.531 142.791L233.653 140.668H252.339L254.169 143.232ZM170.606 101.923H227.487L232.985 96.4216C231.255 87.648 228.016 80.5279 222.805 75.5757C217.51 70.5443 210.07 67.6451 199.787 67.6451C189.905 67.6451 182.321 70.7731 176.663 75.9582C171.111 81.0465 167.347 88.1769 165.139 96.4517L170.606 101.923Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M351.555 91.2356C350.777 84.0709 348.27 78.1791 344.008 74.0581C339.67 69.8635 333.403 67.3984 324.973 67.3984C317.356 67.3984 310.961 69.1523 306.01 72.4158C301.067 75.6732 297.504 80.473 295.594 86.6819L295.521 86.9193L293.327 89.1155H275.38L273.594 86.6132L273.683 86.1852C275.816 75.891 280.721 66.2968 289.095 59.2773C297.474 52.2526 309.244 47.8765 324.973 47.8765C342.086 47.8765 354.34 53.2588 362.306 62.024C370.257 70.772 373.818 82.761 373.818 95.7659V176.385L372 178.205H354.154L352.335 176.385V165.372H346.39C338.273 174.552 325.051 181.167 308.943 181.167C296.713 181.167 286.416 178.234 279.155 172.434C271.864 166.61 267.743 157.98 267.743 146.851C267.743 135.858 271.36 126.995 279.525 119.905C287.634 112.864 300.158 107.636 317.849 103.674L346.143 97.3286L351.555 91.2356ZM296.054 158.593C299.731 161.23 305.226 162.632 312.396 162.632C324.85 162.632 334.618 158.284 341.28 150.93C347.875 143.65 351.509 133.333 351.594 121.13L346.387 116.354L324.212 121.419L324.211 121.419C309.331 124.865 300.906 128.706 296.199 132.913C291.576 137.044 290.46 141.603 290.46 146.851C290.46 152.117 292.429 155.994 296.054 158.593Z"
    />
    <path d="M541.074 64.658C549.643 53.5538 561.901 47.8765 575.603 47.8765C589.369 47.8765 599.797 51.9541 606.759 60.1036C613.697 68.2234 617.049 80.2204 617.049 95.7659V176.385L615.23 178.205H596.151L594.333 176.385V98.2338C594.333 85.9889 591.878 78.5429 587.53 74.1339C583.191 69.7335 576.747 68.1387 568.204 68.1387C560.45 68.1387 554.112 70.8682 549.676 76.8066C545.203 82.7937 542.569 92.1693 542.569 105.637V176.385L540.75 178.205H521.671L519.852 176.385V98.2338C519.852 85.9889 517.398 78.5429 513.05 74.1339C508.71 69.7335 502.267 68.1387 493.724 68.1387C485.97 68.1387 479.632 70.8682 475.196 76.8066C470.723 82.7937 468.088 92.1693 468.088 105.637V176.385L466.27 178.205H447.191L445.372 176.385V53.1514L447.191 51.3315H466.27L468.088 53.1514V59.9691H473.509C479.687 52.2709 489.96 47.8765 501.122 47.8765C509.63 47.8765 516.46 49.4418 522.002 52.406C527.385 55.285 531.492 59.4508 534.73 64.658H541.074Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09868 177.5L0 175.401V2.38261L2.09868 0.283936H72.748C90.4268 0.283936 105.271 4.73369 115.719 13.6351C126.203 22.5665 132.076 35.8237 132.076 53.0239C132.076 66.3622 128.455 77.0136 121.962 85.2174C115.622 93.2274 106.634 98.7903 95.8903 102.288L93.9312 108.166L140.712 174.296L138.424 177.5H115.455L113.149 175.853L64.1558 106.385L62.5993 105.52H30.6693L25.448 110.741V175.401L23.3493 177.5H2.09868ZM30.6693 83.2439H70.308C82.4748 83.2439 91.3937 80.8274 97.258 75.9643C103.065 71.1489 106.14 63.7097 106.14 53.0239C106.14 42.3104 103.23 34.8596 97.8006 30.052C92.3341 25.2119 84.0401 22.8039 72.748 22.8039H30.6693L25.448 28.0253V78.0226L30.6693 83.2439Z"
    />
  </svg>
)

export const RealmLogoPride: FC<any> = (props) => (
  <svg viewBox="0 0 83 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="mask0_13_45" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="83" height="32">
      <path
        d="M76.5039 10.2297L76.3659 10.3687L76.3629 11.4046C76.3629 14.311 76.0709 14.6052 73.1669 14.6052H72.129L71.991 14.7442V15.7489L72.129 15.888L73.1649 15.8908C76.0709 15.8908 76.3659 16.1822 76.3659 19.087V20.1217L76.5039 20.2607H77.509L77.6481 20.1217L77.6511 19.086C77.6511 16.1796 77.9421 15.8854 80.8471 15.8854H81.8849L82.023 15.7463V14.7428L81.8849 14.6038L80.8491 14.601C77.9421 14.601 77.6481 14.3096 77.6481 11.4048V10.3687L77.509 10.2297H76.5039Z"
        fill="white"
      />
      <path
        d="M67.2637 16.8839L67.0551 17.0921L67.0511 18.651C67.0511 23.0247 66.4125 23.6677 62.0416 23.6677H60.4803L60.2717 23.8758V24.9882L60.4803 25.1966L62.0386 25.2005C66.4125 25.2005 67.0551 25.8395 67.0551 30.2109V31.7722L67.2637 31.9803H68.376L68.5836 31.7722L68.5886 30.2123C68.5886 25.8385 69.2272 25.1956 73.5981 25.1956H75.1594L75.3679 24.9874V23.875L75.1594 23.6669L73.6011 23.6629C69.2272 23.6629 68.5836 23.0239 68.5836 18.6526V17.0913L68.376 16.8839H67.2637Z"
        fill="white"
      />
      <path
        d="M39.5232 0.180757V17.518L39.704 17.6989H41.5989L41.7797 17.518V0.180757L41.5989 0H39.704L39.5232 0.180757Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2435 14.2254L25.2266 14.2737C24.8423 15.4059 24.1937 16.3486 23.2899 17.0089C22.3862 17.6691 21.2321 18.0418 19.8426 18.0418C18.0281 18.0418 16.5483 17.363 15.5243 16.1879C14.5004 15.0128 13.9382 13.3534 13.9382 11.3984C13.9382 9.44348 14.5004 7.78329 15.5243 6.60897C16.5483 5.43464 18.0281 4.75491 19.8436 4.75491C21.6592 4.75491 23.0764 5.39611 24.0408 6.54918C25.0051 7.70225 25.5027 9.34873 25.5027 11.3494V11.5132L25.0776 11.9389H16.8224L16.2732 12.5114C16.3983 13.4485 16.734 14.3345 17.3081 14.988C17.8891 15.6496 18.7184 16.0787 19.8426 16.0787C21.392 16.0787 22.3901 15.2947 22.988 14.1942L22.995 14.1817L23.2055 13.9708H25.0618L25.2435 14.2254ZM16.9446 10.1226H22.5937L23.139 9.57637C22.9682 8.70496 22.6464 7.99782 22.1279 7.506C21.6025 7.00624 20.8636 6.71841 19.8436 6.71841C18.8614 6.71841 18.1086 7.02908 17.5464 7.54394C16.9952 8.04926 16.6218 8.7576 16.4023 9.57935L16.9446 10.1226Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9161 9.06131C34.8386 8.3496 34.5893 7.76462 34.1662 7.35524C33.7352 6.9381 33.1135 6.69378 32.2752 6.69378C31.5204 6.69378 30.8848 6.86799 30.3922 7.19216C29.8995 7.51633 29.547 7.99226 29.3573 8.60902L29.3503 8.63245L29.1328 8.85095H27.3501L27.1713 8.60226L27.1802 8.55975C27.3928 7.53738 27.8794 6.58453 28.7097 5.88753C29.54 5.19052 30.711 4.75531 32.2732 4.75531C33.9735 4.75531 35.1902 5.28984 35.9817 6.16045C36.7733 7.03106 37.1249 8.22009 37.1249 9.51161V17.518L36.9461 17.6983H35.1733L34.9945 17.5174V16.4243H34.4036C33.5971 17.336 32.2842 17.9935 30.6842 17.9935C29.4695 17.9935 28.4475 17.7021 27.7265 17.1261C27.0015 16.5476 26.5923 15.6905 26.5923 14.5851C26.5923 13.4926 26.9518 12.6131 27.7622 11.9089C28.5677 11.2097 29.8121 10.6905 31.5691 10.297L34.3788 9.66694L34.9161 9.06131ZM29.403 15.7517C29.7684 16.0135 30.3147 16.1528 31.0268 16.1528C32.2633 16.1528 33.2336 15.7209 33.8951 14.9905C34.5496 14.2675 34.9111 13.2426 34.919 12.0309L34.4026 11.5566L32.1997 12.0597V12.0597C30.7219 12.4019 29.8856 12.7833 29.4189 13.201C28.96 13.6114 28.8488 14.0641 28.8488 14.5853C28.8488 15.1065 29.0434 15.4935 29.403 15.7517Z"
        fill="white"
      />
      <path
        d="M53.7375 6.42165C54.5887 5.31884 55.8063 4.75491 57.167 4.75491C58.5346 4.75491 59.5704 5.15993 60.2617 5.96936C60.9529 6.7788 61.2837 7.96723 61.2837 9.51121V17.518L61.1029 17.6989H59.2079L59.0272 17.518V9.75633C59.0272 8.54009 58.7839 7.80057 58.3518 7.36278C57.9198 6.92499 57.2812 6.76688 56.432 6.76688C55.6623 6.76688 55.0326 7.03802 54.5927 7.62776C54.1477 8.22247 53.8865 9.15347 53.8865 10.4913V17.518L53.7058 17.6989H51.8108L51.63 17.518V9.75633C51.63 8.54009 51.3867 7.80057 50.9547 7.36278C50.5227 6.92499 49.884 6.76688 49.0349 6.76688C48.2652 6.76688 47.6355 7.03802 47.1955 7.62776C46.7506 8.22247 46.4894 9.15347 46.4894 10.4913V17.518L46.3086 17.6989H44.4146L44.2339 17.518V5.27891L44.4146 5.09815H46.3096L46.4904 5.27891V5.95606H47.0287C47.6425 5.19131 48.6624 4.75491 49.7698 4.75491C50.615 4.75491 51.2934 4.91044 51.8436 5.20482C52.3789 5.49085 52.7861 5.90441 53.1079 6.42165H53.7375Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.208566 17.6288L0 17.4202V0.236566L0.208566 0.0281982H7.22533C8.98125 0.0281982 10.4551 0.47016 11.493 1.35428C12.5309 2.2384 13.1178 3.55793 13.1178 5.26619C13.1178 6.59088 12.7573 7.6498 12.1127 8.4642C11.4831 9.25874 10.5902 9.81213 9.52353 10.1595L9.32886 10.7433L13.9749 17.3112L13.7475 17.629H11.4672L11.2377 17.4653L6.37219 10.566L6.21726 10.4799H3.04606L2.52762 10.9984V17.4202L2.31906 17.6286L0.208566 17.6288ZM3.04606 8.26755H6.98299C8.19069 8.26755 9.0766 8.0276 9.65959 7.54452C10.2356 7.06621 10.5415 6.32749 10.5415 5.26619C10.5415 4.20488 10.2525 3.46219 9.71322 2.98467C9.16996 2.50398 8.34662 2.26442 7.22533 2.26442H3.04606L2.52762 2.78306V7.74892L3.04606 8.26755Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13_45)">
      <g filter="url(#filter0_f_13_45)">
        <ellipse
          cx="47.955"
          cy="-2.30859"
          rx="27.4799"
          ry="25.7448"
          transform="rotate(-75 47.955 -2.30859)"
          fill="#004F9D"
        />
      </g>
      <g filter="url(#filter1_f_13_45)">
        <ellipse
          cx="67.955"
          cy="42.6914"
          rx="27.4799"
          ry="25.7448"
          transform="rotate(-75 67.955 42.6914)"
          fill="#004F9D"
        />
      </g>
      <g filter="url(#filter2_f_13_45)">
        <ellipse
          cx="5.97872"
          cy="24.7326"
          rx="28.2576"
          ry="28.7208"
          transform="rotate(-75 5.97872 24.7326)"
          fill="#F9BE03"
        />
      </g>
      <g filter="url(#filter3_f_13_45)">
        <ellipse cx="9.4195" cy="-16.0076" rx="26.5" ry="25.5" transform="rotate(-75 9.4195 -16.0076)" fill="#FA5701" />
      </g>
      <g filter="url(#filter4_f_13_45)">
        <ellipse
          cx="24.9212"
          cy="32.4355"
          rx="25.8207"
          ry="25.0933"
          transform="rotate(-75 24.9212 32.4355)"
          fill="#679B5F"
        />
      </g>
      <g filter="url(#filter5_f_13_45)">
        <ellipse cx="84.686" cy="11.8839" rx="20.6394" ry="24" transform="rotate(-75 84.686 11.8839)" fill="#7F3D9F" />
      </g>
      <g filter="url(#filter6_f_13_45)">
        <circle cx="-11.3085" cy="3.50167" r="13.5" transform="rotate(-75 -11.3085 3.50167)" fill="#FC001A" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_13_45"
        x="2.08374"
        y="-49.6822"
        width="91.7427"
        height="94.7472"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter1_f_13_45"
        x="22.0837"
        y="-4.68216"
        width="91.7427"
        height="94.7472"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter2_f_13_45"
        x="-42.7183"
        y="-23.5634"
        width="97.394"
        height="96.5918"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter3_f_13_45"
        x="-36.1552"
        y="-62.4482"
        width="91.1493"
        height="92.8812"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter4_f_13_45"
        x="-20.2278"
        y="-13.3434"
        width="90.2981"
        height="91.5577"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter5_f_13_45"
        x="40.8909"
        y="-29.0031"
        width="87.5901"
        height="81.774"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter6_f_13_45"
        x="-44.8118"
        y="-30.0017"
        width="67.0066"
        height="67.0067"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
    </defs>
  </svg>
)

export const RealmLogoSmall: FC<any> = (props) => (
  <svg viewBox="0 0 348 327" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M282.442 76L281.049 77.3927L281.021 87.8221C281.021 117.085 278.085 120.049 248.838 120.049H238.393L237 121.442V131.558L238.393 132.951L248.822 132.979C278.085 132.979 281.049 135.915 281.049 165.162V175.607L282.442 177H292.558L293.951 175.607L293.979 165.178C293.979 135.915 296.915 132.951 326.162 132.951H336.607L338 131.558V131.531V121.442L336.607 120.049L326.178 120.021C296.915 120.021 293.951 117.085 293.951 87.8381V77.3927L292.558 76H282.442Z" />
    <path d="M189.4 143L187.305 145.096L187.263 160.792C187.263 204.831 180.831 211.305 136.816 211.305H121.096L119 213.4V224.599L121.096 226.695L136.792 226.737C180.831 226.737 187.305 233.169 187.305 277.184V292.904L189.4 295H200.6L202.696 292.904L202.737 277.208C202.737 233.169 209.169 226.695 253.184 226.695H268.904L271 224.6V224.558V213.4L268.904 211.305L253.208 211.263C209.169 211.263 202.695 204.831 202.695 160.816V145.096L200.6 143H189.4Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09868 177.216L0 175.117V2.09868L2.09868 0H72.748C90.4268 0 105.271 4.44975 115.719 13.3512C126.203 22.2826 132.076 35.5397 132.076 52.74C132.076 66.0782 128.455 76.7297 121.962 84.9334C115.622 92.9434 106.634 98.5064 95.8903 102.005L93.9312 107.882L140.712 174.012L138.424 177.216H115.455L113.149 175.569L64.1558 106.101L62.5993 105.236H30.6693L25.448 110.457V175.117L23.3493 177.216H2.09868ZM30.6693 82.96H70.308C82.4748 82.96 91.3937 80.5434 97.258 75.6804C103.065 70.865 106.14 63.4257 106.14 52.74C106.14 42.0264 103.23 34.5757 97.8006 29.7681C92.3341 24.9279 84.0401 22.52 72.748 22.52H30.6693L25.448 27.7413V77.7387L30.6693 82.96Z"
    />
  </svg>
)

export const RealmLogoSmallPride: FC<any> = (props) => (
  <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="mask0_14_87" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.222935 18.8311L0 18.6082V0.222935L0.222935 0H7.73047C9.60917 0 11.1861 0.472861 12.2965 1.41879C13.4069 2.36473 14.0349 3.77651 14.0349 5.6042C14.0349 7.0215 13.6492 8.15445 12.9596 9.02579C12.2859 9.87715 11.3306 10.4682 10.1893 10.8399L9.98108 11.4645L14.952 18.4915L14.7086 18.8315H12.2689L12.0234 18.6566L6.81769 11.2743L6.65193 11.1825H3.25902L2.70434 11.7374V18.6082L2.48119 18.8311H0.222935ZM29.8635 8.22459L30.0123 8.07582H31.0898L31.2386 8.22459V9.3331C31.2386 12.441 31.5531 12.7528 34.6633 12.7528L35.7716 12.7558L35.9204 12.9045V13.9782L35.7716 14.1269H34.6612C31.5531 14.1269 31.2417 14.4417 31.2417 17.5513L31.2386 18.6594L31.0898 18.8082H30.0144L29.8657 18.6594V17.5524C29.8657 14.4445 29.5511 14.1327 26.4409 14.1327L25.3326 14.1297L25.1838 13.9809V12.906L25.3326 12.7572H26.4409C29.549 12.7572 29.8603 12.4425 29.8603 9.33289L29.8635 8.22459ZM19.9026 15.418L20.1258 15.1953H21.3159L21.5391 15.418V17.0884C21.5391 21.7654 22.2266 22.4491 26.9063 22.4491L28.5735 22.4533L28.7967 22.6761V23.8662L28.5735 24.0889H26.9031C22.2266 24.0889 21.5433 24.7768 21.5433 29.4563L21.5391 31.1242L21.3159 31.3469H20.1258L19.9026 31.1244V29.454C19.9026 24.777 19.2151 24.0934 14.5354 24.0934L12.8682 24.0891L12.645 23.8662V22.6761L12.8682 22.4542H14.5386C19.2151 22.4542 19.8984 21.7663 19.8984 17.0867L19.9026 15.418ZM7.4712 8.8154H3.25902L2.70434 8.26093V2.94789L3.25902 2.39299H7.73047C8.93016 2.39299 9.81106 2.64887 10.3923 3.16317C10.9693 3.67407 11.2785 4.46869 11.2785 5.6042C11.2785 6.7397 10.9512 7.53007 10.3349 8.04182C9.71117 8.55846 8.76333 8.8154 7.4712 8.8154Z"
        fill="#4A957A"
      />
    </mask>
    <g mask="url(#mask0_14_87)">
      <g opacity="0.8" filter="url(#filter0_f_14_87)">
        <ellipse cx="24" cy="13.5001" rx="15" ry="14.5" fill="#004F9D" />
      </g>
      <g opacity="0.8" filter="url(#filter1_f_14_87)">
        <ellipse cy="1.50009" rx="15" ry="15.5" fill="#F9BE03" />
      </g>
      <g opacity="0.8" filter="url(#filter2_f_14_87)">
        <ellipse cx="16" cy="1.50009" rx="13" ry="12.5" fill="#FA5701" />
      </g>
      <g opacity="0.8" filter="url(#filter3_f_14_87)">
        <ellipse cx="9.5" cy="25.0001" rx="13.5" ry="13" fill="#679B5F" />
      </g>
      <g opacity="0.8" filter="url(#filter4_f_14_87)">
        <ellipse cx="39" cy="22.5001" rx="13" ry="12.5" fill="#7F3D9F" />
      </g>
      <g opacity="0.8" filter="url(#filter5_f_14_87)">
        <circle cx="-0.5" cy="0.500092" r="3.5" fill="#FC001A" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_14_87"
        x="1"
        y="-8.99991"
        width="46"
        height="45"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_14_87" />
      </filter>
      <filter
        id="filter1_f_14_87"
        x="-23"
        y="-21.9999"
        width="46"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_14_87" />
      </filter>
      <filter
        id="filter2_f_14_87"
        x="-5"
        y="-18.9999"
        width="42"
        height="41"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_14_87" />
      </filter>
      <filter
        id="filter3_f_14_87"
        x="-12"
        y="4.00009"
        width="43"
        height="42"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_14_87" />
      </filter>
      <filter
        id="filter4_f_14_87"
        x="18"
        y="2.00009"
        width="42"
        height="41"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_14_87" />
      </filter>
      <filter
        id="filter5_f_14_87"
        x="-12"
        y="-10.9999"
        width="23"
        height="23"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_14_87" />
      </filter>
    </defs>
  </svg>
)

export default RealmLogo
