import { track as legacyTrack, trackSync as legacyTrackSync, trackPage as legacyTrackPage } from './analytics'

/**
 * import { trackClick, trackPage, trackImpression }
 * trackClick({ ... })
 *    // Traditional replacement of 'track', when we're tracking a 'click' of any type
 * trackImpression({ ... })
 *    // New 'impression' for when we show something to a user.
 * trackPage(pageName)
 *    // Drop in replacement of 'trackPage'
 *
 * // When a page will be tracking multiple times, but using the same
 * // base set of options, you can prepare a local track
 * const trackOnboardinClick = prepare({ family: 'onboarding', screen: 'enter-address' }, trackClick)
 * // And then call it to fire the tracking event
 * trackOnboardinClick({ code: 'test', feature: 'test-feature' })
 *
 * // Another convenience function is available if you know you'll be using both 'click'
 * // and 'impression' events, but that they'll share some of the same options.
 * const [trackOnboardingClick, trackOnboardingImpression] = prepareBoth({ feature: 'test' })
 */
export interface TrackBase {
  code?: string
  screen?: string
  section?: string
  feature?: string
  family?: string
  content_rank?: string
  property_id?: string
  flexfield_1?: any
  flexfield_2?: any
  content_text?: string
}

interface TrackOptions extends TrackBase {
  code: string
}

type TrackNameTypes = 'impression' | 'click' | 'page-view' | 'state-event'

type TrackFn = (name: TrackNameTypes, options?: TrackBase) => Promise<void>
type TrackSyncFn = (name: TrackNameTypes, options?: TrackBase) => void

type TrackNameFn<T extends TrackBase> = (options: T) => Promise<void>
type TrackNameSyncFn<T extends TrackBase> = (options: T) => void
type TrackNameOptionalFn<T extends TrackBase> = (options?: T) => Promise<void>

export const track: TrackFn = (name, options) => {
  if (name == 'page-view') legacyTrackPage(options?.screen)
  return legacyTrack(name, options)
}
export const trackSync: TrackSyncFn = (name, options) => {
  legacyTrackSync(name, options)
}

export function prepareName<T extends TrackBase>(
  name: TrackNameTypes,
  preparedOptions?: T,
  trackFn: TrackFn = track
): TrackNameFn<T> {
  return (options) => trackFn(name, { ...options, ...preparedOptions })
}
export function prepareNameSync<T extends TrackBase>(
  name: TrackNameTypes,
  preparedOptions?: T,
  trackFn: TrackSyncFn = trackSync
): TrackNameSyncFn<T> {
  return (options) => trackFn(name, { ...options, ...preparedOptions })
}
export function prepareNameOptional<T extends TrackBase>(
  name: TrackNameTypes,
  preparedOptions?: T,
  trackFn: TrackFn = track
): TrackNameOptionalFn<T> {
  return (options) => trackFn(name, { ...options, ...preparedOptions })
}
export function prepare<T extends TrackBase>(
  preparedOptions: TrackBase,
  trackFn: TrackNameFn<T>
): TrackNameOptionalFn<T> {
  return (options) => trackFn({ ...options, ...(preparedOptions as T) })
}

export function prepareBoth(
  preparedOptions: TrackBase,
  trackFn: TrackFn = track
): [TrackNameFn<TrackOptions>, TrackNameFn<TrackOptions>] {
  const trackClick = (options) => trackFn('click', { ...options, ...preparedOptions })
  const trackImpression = (options) => trackFn('impression', { ...options, ...preparedOptions })
  return [trackClick, trackImpression]
}

export const trackPageOptions = prepareNameOptional<TrackBase>('page-view')
export const trackPage = (screen?: string): Promise<void> => trackPageOptions({ screen })
export const trackClick = prepareName<TrackOptions>('click')
export const trackCickSync = prepareNameSync<TrackOptions>('click')
export const trackImpression = prepareName<TrackOptions>('impression')
