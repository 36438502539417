import { formatAddressCasing, formatPublicPropertyAddress } from 'utils/formatting'

export const generateIndexedUrlPathForPublicProperty = (property: AttomTaxAssessor): string => {
  const state = property.propertyaddressstate.toLowerCase()
  const city = (property.propertyaddresscity || '').replace(/\W+/g, '-').toLowerCase()
  const zip = property.propertyaddresszip
  const address = (property.propertyaddressfull || '').replace(/\W+/g, '-').toLowerCase()
  const attomid = property.attom_id

  return `/${state}/${city}/${address}-${city}-${state}-${zip}/${attomid}`
}

export const generateUrlPathForPublicProperty = (property: AttomTaxAssessor): string => {
  return `/public_properties/${property.attom_id}`
}

export const signUpPathWithPublicProperty = (publicProperty: AttomTaxAssessor): string => {
  const address = encodeURIComponent(formatAddressCasing(publicProperty.propertyaddressfull))

  return publicProperty ? `/users/sign_up?attom_id=${publicProperty.attom_id}&address=${address}` : '/users/sign_up'
}

export const missingBedsAndBaths = (publicProperty: AttomTaxAssessor): boolean => {
  const bedroomsCount = parseInt(publicProperty?.bedroomscount || '0')
  let totalBathroomsCount = parseInt(publicProperty?.bathcount || '0')
  totalBathroomsCount += parseInt(publicProperty?.bathpartialcount || '0')

  return bedroomsCount == 0 && totalBathroomsCount == 0
}

export const getPublicPropertyMapUrl = (
  publicProperty: AttomTaxAssessor | undefined,
  width = 1000,
  height = 1000
): string | undefined => {
  if (!publicProperty) return
  // Mapbox static image API doesn't support searching by address. We don't want to geocode ourselves because $$, but google maps supports it at no additional cost
  const hasLatLng =
    publicProperty?.propertylatitude != null &&
    publicProperty.propertylatitude != '' &&
    publicProperty?.propertylongitude != null &&
    publicProperty.propertylongitude != ''
  const mapboxUrl = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${publicProperty.propertylongitude},${publicProperty.propertylatitude},17,0/${width}x${height}@2x?access_token=pk.eyJ1Ijoicm9od2VpZ2VsIiwiYSI6ImNrYmg1YWh2YzAyMjkyem5yN2RsMmRpZXkifQ.STA87m4W37moWj2evrjymw`
  const googleMapsUrls = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURI(
    formatPublicPropertyAddress(publicProperty)
  )}&zoom=16&scale=2&maptype=satellite&size=${width}x${height}&key=AIzaSyDIABYI0DmyZHUfhoJxVXKDXJ_4znAEE8E`

  return hasLatLng ? mapboxUrl : googleMapsUrls
}
